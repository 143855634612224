export const MIN_PASSWORD_LENGTH = 8;

export function generatePassword(length = MIN_PASSWORD_LENGTH) {
  const lower = "abcdefghijklmnopqrstuvwxyz";
  const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const allChars = lower + upper + numbers;

  const password = [
    lower.charAt(Math.floor(Math.random() * lower.length)),
    upper.charAt(Math.floor(Math.random() * upper.length)),
    numbers.charAt(Math.floor(Math.random() * numbers.length)),
  ];

  while (password.length < length) {
    password.push(allChars.charAt(Math.random() * allChars.length));
  }

  password.sort(() => Math.random() - 0.5);

  return password.join("");
}

export function isValidPassword(password) {
  const hasNumber = /\d/;
  const hasLowercase = /[a-z]/;
  const hasUppercase = /[A-Z]/;

  return (
    password.length >= MIN_PASSWORD_LENGTH &&
    hasNumber.test(password) &&
    hasLowercase.test(password) &&
    hasUppercase.test(password)
  );
}
