import React from "react";

export default function KakNachatRabotuVSistemePageFAQ() {
  return (
    <article>
      <h1>Как начать работу в системе Смартан</h1>
      <p>
        После оплаты и заключения договора, на вашу электронную почту придет
        письмо со ссылкой на сервис и ссылкой на установку пароля. Перейдите по
        ссылке:
      </p>
      <img src="img/img.png" alt="" />
      <p>
        Установите пароль. Внимание: минимальная длина пароля - 8 символов.
        Пароль должен включать как минимум одну цифру, одну прописную и одну
        строчную букву.
      </p>
      <img src="img/img3.png" alt="" />
    </article>
  );
}
