import {
  CircularProgress,
  FormControl,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  loader: {
    "& .MuiCircularProgress-root": {
      position: "absolute",
      right: "12px",
      top: "13px",
    },
  },
}));

export function FetchOnceAutocomplete<T extends Named>({
  id,
  label,
  fetchOptions,
  onChange,
  error,
  helperText,
  value,
}: FetchOnceAutocompleteProps<T>) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<T[]>([]);
  const [fetched, setFetched] = useState(false);
  const loading = open && options.length === 0;

  const classes = useStyles();

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      if (fetched) {
        return;
      }

      const fetchedOptions = await fetchOptions();
      fetchedOptions
        .filter((i) => !i.name)
        .forEach((i) => {
          i.name = "";
        });
      setFetched(true);
      setOptions(fetchedOptions);
    })();
  }, [loading, fetched]);

  let IconComponent = () => {
    return (
      <div style={{ position: "relative", right: "18px", top: "5px" }}>
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.710051 2.21L3.30005 4.8C3.69005 5.19 4.32005 5.19 4.71005 4.8L7.30005 2.21C7.93005 1.58 7.48005 0.5 6.59005 0.5H1.41005C0.520051 0.5 0.0800515 1.58 0.710051 2.21Z"
            fill="#54606F"
          />
        </svg>
      </div>
    );
  };

  return (
    <FormControl
      className="AutoCompleteInput"
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <label htmlFor={id}>
        <Typography>{label}</Typography>
      </label>
      <Autocomplete
        className={classes.loader}
        popupIcon={<IconComponent />}
        value={value}
        fullWidth
        id={id}
        open={open}
        disableClearable
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(_event, newValue) => {
          onChange(newValue);
        }}
        getOptionLabel={(option) =>
          option.name === undefined ? option : option.name
        }
        // getOptionSelected={(a,b) => a.name === b.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

interface FetchOnceAutocompleteProps<T extends Named> {
  id: string;
  label: string;
  fetchOptions: () => Promise<Array<T>>;
  onChange: (el: T) => void;
  error?: boolean;
  helperText?: string;
  value: any;
}

interface Named {
  name: string;
}
