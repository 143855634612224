import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import DateIcon from "../svgComponents/DateIcon";

const FormDateRow = ({
  label,
  onChange,
  value,
  dateFormat,
  dates,
  disabled,
  maxDate = null,
  minDate = null,
}) => {
  const { t, i18n } = useTranslation();

  if (dateFormat === undefined) {
    dateFormat = i18n.language === "ru" ? "dd.MM.yyyy" : "MM/dd/yyyy";
  }

  const pathThrough = {};
  if (maxDate) pathThrough.maxDate = maxDate;
  if (minDate) pathThrough.minDate = minDate;

  return (
    <MuiPickersUtilsProvider
      className="DefaultDate"
      utils={DateFnsUtils}
      locale={i18n.language === "en" ? enLocale : ruLocale}
    >
      <div className="FormDateRow">
        <Typography component="p">{t(label)}</Typography>
        <KeyboardDatePicker
          disabled={disabled}
          autoOk
          variant="inline"
          views={dates ? ["year", "month", "date"] : ["year", "month"]}
          inputVariant="outlined"
          format={dateFormat}
          value={value}
          InputAdornmentProps={{ position: "end" }}
          onChange={(date, value) => {
            onChange(value, date);
          }}
          keyboardIcon={!disabled && <DateIcon />}
          {...pathThrough}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

FormDateRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  dates: PropTypes.string,
};

export default FormDateRow;
