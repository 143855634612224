import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PasswordInput from "@View/components/passwordField/PasswordInput";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import { Snackbar } from "@State/actions/actionCreators";
import AppAPI from "@API/index";
import { isValidPassword } from "../../security";

const FormCover = styled(Grid)(() => ({
  width: "100%",
  "& .MuiGrid-item": {
    paddingLeft: 0,
    width: "100%",
  },
  "& .MuiTextField-root": {
    width: "100%",
  },
  "& .MuiInputBase-input": {
    display: "block",
    width: "100%",
  },
  padding: "20px",
}));

const SetupPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  const formRef = useRef<ValidatorForm | null>();

  const submitForm = () => {
    (async () => {
      if (formRef.current) {
        const isFormValid = await formRef.current.isFormValid(true);
        if (isFormValid) {
          const URLParams = new URLSearchParams(window.location.search);
          const email = URLParams.get("email");
          const token = URLParams.get("token");
          if (email && token) {
            await AppAPI.request({
              method: "post",
              url: `/api/account/reset-password`,
              data: {
                email: email,
                password: form.password,
                confirmPassword: form.confirmPassword,
                token,
              },
              successText: t("PasswordSaved"),
            });
            setTimeout(() => {
              window.location.href = "/";
            }, 5000);
          } else {
            dispatch(Snackbar({ message: t("FormIncorrect"), open: true }));
          }
        } else {
          dispatch(Snackbar({ message: t("FormIncorrect"), open: true }));
        }
      }
    })();
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      return form.password === value;
    });
    return () => {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [form.password]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .MuiGrid-container": {
          marginLeft: 0,
          marginTop: "-30px",
        },
      }}
    >
      <ValidatorForm
        ref={(r) => (formRef.current = r)}
        onSubmit={(event) => {
          submitForm();
        }}
        instantValidate={true}
        component="form"
        autoComplete="off"
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <FormCover
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <Typography variant="h5">{t("SetupPassword")}</Typography>
          </Grid>
          <Grid item>
            <PasswordInput
              autoFocus
              value={form.password}
              label={t("New password")}
              onChange={(value: string) =>
                setForm({ ...form, password: value })
              }
            />
          </Grid>
          <Grid item>
            <PasswordInput
              value={form.confirmPassword}
              label={t("Confirm password")}
              customValidatorNames={["isPasswordMatch"]}
              onChange={(value: string) =>
                setForm({ ...form, confirmPassword: value })
              }
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => submitForm()}
              disabled={form.password ? !isValidPassword(form.password) : false}
            >
              {t("buttons.Save")}
            </Button>
          </Grid>
        </FormCover>
      </ValidatorForm>
    </Box>
  );
};

export default SetupPassword;
