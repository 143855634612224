import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Divider from "@mui/material/Divider";
import UserName from "./UserName";
import LinkCell from "../links/LinkCell";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { logout } from "@State/effects/security/logout";
import UserIcon from "../svgComponents/UserIcon";
import LogOutIcon from "../svgComponents/LogOutIcon";
import UserDetailsPage from "@View/pages/AdminPO/user/UserDetailsPage";
import { useTranslation } from "react-i18next";
import Localization from "../Localization";
import DropDownIcon from "@View/components/svgComponents/DropDownIcon";
import { getUserId, getUserName } from "@Security/stateHelpers";

const useStyles = makeStyles(() => ({
  dropdown: {
    margin: "0 2.2em 0 0",
  },
}));

const useMenuItemStyles = makeStyles(() => ({
  root: {
    "font-size": "14px",
    padding: "10px 0",
    color: "#54606F",
    "& a": {
      "font-size": "14px",
      color: "#54606F !important",
    },
    "&:hover": {
      "background-color": "transparent",
      color: "#000",
      "& a": {
        color: "#000 !important",
      },
    },
  },
}));

const useMenuTitleStyles = makeStyles(() => ({
  root: {
    "font-size": "24px",
    padding: "5px 0",
    color: "#1A1E24",
    "font-weight": "600",
    "&:hover": {
      "background-color": "transparent",
    },
  },
}));

export const DropdownMenu = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const logoutDispatch = () => logout()(dispatch);

  const menuItemStyles = useMenuItemStyles();
  const menuTitleStyles = useMenuTitleStyles();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const [openDetails, setOpenDetails] = React.useState(false);

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <UserIcon />
          <UserName />
          <DropDownIcon />
        </Button>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.dropdown}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ width: "300px", marginRight: "150px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  style={{
                    padding: "20px",
                  }}
                >
                  <MenuItem classes={menuTitleStyles} onClick={handleClose}>
                    <div onClick={handleOpenDetails}>{getUserName()}</div>
                  </MenuItem>
                  <Divider sx={{ margin: "10px 0" }} />
                  <MenuItem classes={menuItemStyles} onClick={handleClose}>
                    <div onClick={handleOpenDetails}>{t("User Details")}</div>
                  </MenuItem>
                  <MenuItem classes={menuItemStyles} onClick={handleClose}>
                    <LinkCell
                      path={`/user/changepassword/${getUserId()}`}
                      textLink="Change Password"
                    />
                  </MenuItem>
                  <MenuItem classes={menuItemStyles} onClick={handleClose}>
                    <LinkCell path={`/faq/faqpage`} textLink="FAQ" />
                  </MenuItem>
                  <MenuItem classes={menuItemStyles} onClick={handleClose}>
                    <div onClick={() => logoutDispatch()}>{t("Log Out")}</div>
                  </MenuItem>

                  {window.location.href.indexOf("findatarequest") !==
                  -1 ? null : (
                    <>
                      <Divider sx={{ margin: "10px 0" }} />
                      <Localization />
                    </>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <UserDetailsPage
        userId={getUserId()}
        open={openDetails}
        handleOpen={handleOpenDetails}
        handleClose={handleCloseDetails}
      />
    </React.Fragment>
  );
};
