import { makeStyles } from "@material-ui/core";

export const useTooltipStyle = makeStyles(() => ({
  tooltip: {
    background: "#000",
  },
  element: {
    color: "#9C9C9C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: "#000",
    },
  },
  inactive: {
    visibility: "hidden",
  },
}));

export const useCTAButtonStyle = makeStyles(() => ({
  default: {
    background: "#54606F",
    color: "#F3F5F7",
    "&:hover": {
      background: "#394554",
      color: "#F3F5F7",
    },
    "&:disabled": {
      background: "rgba(0, 0, 0, 0.03)",
      color: "#54606F",
    },
  },
}));
